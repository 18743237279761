.input{
  background-color: #2b3139!important;
  border: 1px solid #2b3139!important;
  color: rgb(255, 255, 255) !important;
  svg{
    fill: #ffffff;
  }
  :global(.ant-input){
    background-color: #2b3139;
    border: 1px solid #2b3139;
    color: rgb(255, 255, 255);
  }
}
.label{
  color: #ffffff;
  margin-bottom: 7px;
  display: block;
}