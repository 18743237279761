.content{
  display: flex;
  align-items: center;
}
.contentLeft{
  padding-right: 7px;
}
.contentRight{
  padding-left: 7px;
}
.tooltip{
  :global(.ant-tooltip-inner){
    min-height: 20px;
    padding: 3px 8px;
    font-size: 12px;
    background-color: #ffffff;
    color: #0e1f1e;
    text-align: center;
  }
  :global(.ant-tooltip-arrow-content){
    background-color: #ffffff;
    color: #ffffff;
    --antd-arrow-background-color: rgb(255 255 255 / 75%);
  }
  :global(.ant-tooltip-content){
    max-width: 180px;
  }
}

.icon{
  font-size: 16px;
  //color: #1890ff;
  //background-color: #1890ff;
  border-radius: 50%;
}
