.auth{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.auth-form{
  width: 350px;
}
.or-in-form{
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    right: 60%;
    background-color: #4b4b4b;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after{
    content: '';
    position: absolute;
    right: 0;
    height: 1px;
    left: 60%;
    background-color: #4b4b4b;
    top: 50%;
    transform: translateY(-50%);
  }
}
