@import "src/assets/styles/popover";
.button{
  width: 20px;
  height: 20px;
  margin: 0 20px;
  svg{
    fill: #ffffff;
  }
}
.content{
  :global(.ant-tag){
    color: #ffffff;
    border: 1px solid #1890ff;
  }
}
.label{
  color: #ffffff;
  margin-bottom: 7px;
  display: block;
}
.section{
  border-bottom: 1px solid #2b313a;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

