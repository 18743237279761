@import "./src/assets/styles/modal";
@import "./src/assets/styles/table";
.screener{
  display: flex;
  width: 80%;
  margin: 0 10px;
}

.screenerItem{
  width: 45%;
  padding: 20px 10px;
}
.content{
  height: calc(100vh - 100px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 1px;
    background-color: #1e2329;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: #096dd9;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
  }
}
.screenerItemContainer{
  background-color: #2b3139;
  display: flex;
  align-items: center;
  justify-content: center;

}
.buttonAdd{
  display: flex;
  justify-content: center;
  padding: 20px;
}

