@import "./src/assets/styles/modal";
@import "./src/assets/styles/table";

.modal{
  width: 750px;
}

.tableTitle{
  display: flex;
  align-items: center;
}
.logo{
  margin-right: 10px;
  width: 20px;
}
.inputSearch{
  margin-bottom: 20px;
}
.tag{
  margin-bottom: 10px!important;
}
