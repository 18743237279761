.header{
  height: 60px;
  background-color: #1E2329;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2B313A;
}
.headerRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item{
  display: flex;
  align-items: center;
}

.left{

}

.right{

}

.button{
  width: 20px;
  height: 20px;
  color: #ffffff;
  margin: 0 20px;
  &:hover{
    svg{
      fill: #1890FF;
    }
  }
  svg{
    fill: #ffffff;
  }
}

.warning{
  display: flex;
  align-items: center;
  margin-right: 20px;
  a{
    margin-left: 10px;
    color: #349cff;
    border-bottom: 1px solid #349cff;
  }
}
.warningIcon{
  margin-right: 10px;
  font-size: 20px;
}
