.select{
  color: #ffffff!important;
  :global(.ant-select-selector){
    background-color: #1e2329!important;
    border: 0!important;
  }
  :global(.ant-select){
    color: #ffffff!important;
  }
  :global(.ant-select-arrow){
    color: rgb(255 255 255 / 85%);
  }
}
.selectRow{
  margin-right: 20px;
}