.th{
  border: 1px solid #2B313A;
  line-height: 2;
  border-right-width: 0;
  padding: 0.25rem 4px 0.25rem 5px;
  text-align: left;
  font-size: 12px;
  &:last-child{
    border-right-width: 1px;
  }
}
