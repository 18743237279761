.modal{
  :global(.ant-modal-header){
    background: #1e2329;
    border-bottom: 1px solid #2b313a;
  }
  :global(.ant-modal-content){
    background: #1e2329;
  }
  :global(.ant-modal-body){
    background: #1e2329;
    color: #ffffff;
  }
  :global(.ant-modal-footer){
    background: #1e2329;
    border-top: 1px solid #2b313a;
  }
  :global(.ant-modal-title){
    color: #ffffff;
  }
  :global(.ant-modal-close-x){
    color: #ffffff;
  }
  :global(.ant-modal-close-icon){
    padding: 10px;
    border-radius: 3px;
    &:hover{
      background: #303944;
    }
  }
}