.density{
  width: 55%;
  padding: 20px 10px;

}
.empty{
  //background-color: #2b3139;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #2b313a;
}
.body{
  //background-color: #2b3139;
  height: 100%;
}
.content{
  padding: 10px 0;
}
.header{
  display: flex;
  //border-bottom: 1px solid #4a4a4a;
  //padding: 10px;
}
.info{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.charts-check{
  width: 100%;
  height: 100%;
}
