.container{
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
}
.display-flex{
  display: flex;
}
.text-center{
  text-align: center;
}
.row-min-pg-8{
  margin: 0 -8px;
}
.col-pg-8{
  padding: 0 8px;
}