@import "src/assets/styles/popover";
.avatar{
  cursor: pointer;
}
.headerDrawer{
  display: flex;
  align-items: center;
}

.displayName{
  margin-left: 15px;
  color: #cecece;
  font-weight: 600;
  line-height: 1;
  &:hover{
    color: #cecece;
  }
  .tag{
    padding-left: 10px;
  }
}

.popoverHeader{
  display: flex;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #242a2f;
  &:hover{
    background-color: #285191;
  }
}
.popoverHeaderAnnotation{
  font-size: 10px;
  color: #b0b0b0;
}
.popoverBody{
  padding: 10px 0;
  ul{
    li {
      font-size: 14px;
      padding: 5px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover{
        background-color: #285191;
      }
    }
  }
}
.popoverContent{
  padding: 10px 0;
}

.popoverFooter{
  padding-top: 10px;
  border-top: 1px solid #242a2f;
}

.textLi{
  padding-left: 15px;
}
