.row{
  display: flex;
  width: 100%;
}

.cup{
  height: calc(50vh - 115px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
    background-color: #212121;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: #096dd9;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #253861;
  }
}

.bids{
  padding: 7px 0;
  background-color: #152b28;
}

.asks{
  background-color: #2c1e24;
  padding: 7px 0;
}
.price{
  margin-right: 50px;
}

.red{
  background-color: #2c1e24;
  color: #f6465d;
  padding: 20px 0;
  &:hover{
    background-color: #1e1319;
  }
}
.green{
  background-color: #152b28;
  color: #0ecb81;
  &:hover{
    background-color: #0e1f1e;
  }
}
.item{
  position: relative;
  padding: 3px 15px;
  font-size: 13px;
  cursor: pointer;
}
.bg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#5c0a5c, .5);
}

.maxValue{
  .bg{
    background-color: #5c0a5c;
  }
}

.result{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}