@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  word-spacing: 1px;
  color: #fff;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body{
  background-color: #1E2329;
  color: #ffffffd9;
}
ul, li, p, a {
  color: #ffffffd9;
}
h1, h2, h3, h4,h5, h6{
  color: #ffffff!important;
}

img{
  max-width: 100%;
}
button{
  padding: 0;
  background-color: rgba(#000, 0);
  border: 0;
  &:hover{
    cursor: pointer;
  }
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}
