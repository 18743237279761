@import "./src/assets/styles/modal";
.row{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.title{
  display: flex;
  align-items: center;
}

.titlePrice{
  font-size: 14px;
  margin-right: 10px;
  span{
    font-size: 12px;
  }
}
