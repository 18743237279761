.popover{
  :global(.ant-popover-inner){
    background-color: #2b3139;
  }
  :global(.ant-popover-title){
    color: #ffffffd9;
    border-bottom: 1px solid #242a2f;
  }
  :global(.ant-popover-arrow-content){
    background-color: #1e2329;
  }
  :global(.ant-popover-inner-content){
    padding: 0;
    //max-width: 310px;
  }
  :global(.ant-popover-arrow-content:before){
    background: linear-gradient(270deg,#2b313a 50%,#2b313a 0) no-repeat -10px -10px;
  }
}
