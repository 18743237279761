.chart{
  border: 1px solid #2b313a;
  //border-right: 0;
}
.intervals{
  display: flex;
  border: 1px solid #2b313a;
  border-bottom: 0;
  padding: 5px 10px;
}
.interval{
  margin-right: 15px;
  font-size: 12px;
  color: #848e9c;
  font-weight: 500;
  cursor: pointer;
  &:hover{
    color: #096dd9;
  }
}
.intervalActive{
  color: #096dd9;
}
