@import "src/assets/styles/popover";
.td{
  border: 1px solid #2B313A;
  border-top-width: 0;
  border-right-width: 0;
  font-size: 13px;
  width: 110px;
  line-height: 1.28;
  cursor: pointer;
  position: relative;
  &:hover{
    background-color: #2B313A;
    .green{
      background-color: #0a5222;
    }
    .red{
      background-color: #621e2b;
    }
  }
  &:last-child{
    border-right-width: 1px;
  }
}
.tr{
  cursor: context-menu;
  &:hover{
    background-color: #1e2329;
  }
}
.symbol{
  max-width: 100px;
  padding: 5px 10px;
}
.tdContent{
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.wrap{
  min-width: 80px;
  height: 21px;
  padding: 5px 6px;
}
.candles{
  width: 150px;
}
.toolTip{
  font-size: 12px;
}

.bids{
  background-color: #152b28;
}

.asks{
  background-color: #2c1e24;
}

.green{
  background-color: #0c652b;
}
.red{
  background-color: #732535;
}

.bg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.list{
  li{
    padding: 5px 16px;
    cursor: pointer;
    &:hover{

      background-color: #285191;
    }
    .menuTitle{
      padding-left: 10px;
    }
  }
}
.symbolActive{
  background-color: #2B313A;
}
.selected{
  background-color: #285191;
  &:hover{
    background-color: #285191;
  }
  .green{
    background-color: #083f19;
  }
  .red{
    background-color: #521723;
  }
}
