.table{
  margin: 20px 0;
  :global(.ant-table){
    background-color: #1e2329;
  }
  :global(.ant-table-row-selected){
    :global(.ant-table-cell){
      background-color: #2b3139;
      color: #ffffff;
      border-color: #2b3139;
    }
  }
  :global(.ant-table-cell){
    background-color: #1e2329;
    color: #ffffff;
    border-color: #2b3139;
    cursor: pointer;
  }
  :global(.ant-table-cell-row-hover){
    background-color: #2b3139!important;
  }
  :global(.ant-table-header){
    background-color: #1e2329;
    :global(.ant-table-cell-scrollbar){
      display: none;
    }
    :global(.ant-table-cell){
      font-size: 13px;
      color: #aaa;
    }
  }
  :global(.ant-pagination-item){
    a{
      color: #ffffff!important;
    }
  }
  :global(.ant-pagination-item-link){
    color: #ffffff!important;
  }
  :global(.ant-pagination-item-active){
    background-color: #1e2329;
  }
  :global(.ant-table-body){
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #1e2329;
    }

    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      background-color: #096dd9;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #253861;
    }
  }
}
