.input{

  &:global(.ant-input){
    background-color: #2b3139;
    border: 1px solid #2b3139;
    color: rgb(132, 142, 156);
  }
}
.label{
  color: #ffffff;
  margin-bottom: 7px;
  display: block;
}