.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container{
  display: flex;
  height: calc(100vh - 60px);
}
